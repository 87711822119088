<template>
    <b-card>
        <!-- 暂时不需要编辑企业的角色
    <b-row>    
      <b-col cols="2" class="mt-1">
        <b-form-checkbox v-model="isIT" switch inline>{{isIT?'平台角色':'企业角色'}}</b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <vue-autosuggest
          v-if="!isIT"
          v-model="ent_name"
          :suggestions="ent_list"
          :input-props="inputProps"
          :section-configs="sectionConfigs"
          :render-suggestion="renderSuggestion"
          :get-suggestion-value="getSuggestionValue"
          @input="loadEnterprises"
        />
      </b-col>
    </b-row> -->
        <b-row>
            <!-- role list --->
            <b-col cols="6">
                <b-card>
                    <b-card-header>
                        <b-card-title>角色列表</b-card-title>
                        <b-card-title>
                            <b-button variant="primary" @click="onNewRole" :disabled="!isIT">
                                <feather-icon icon="PlusIcon" />
                                <span class="align-middle"> 新建角色 </span></b-button>
                        </b-card-title>
                    </b-card-header>
                    <b-col cols="12">
                        <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="roles" :fields="fields" @row-clicked="onRoleClicked">
                            <template #cell(avatar)="data">
                                <b-avatar :src="data.value" />
                            </template>
                            <template #cell(rights)="data">
                                <b-badge v-for="(item, idx) in data.value" :key="idx" class="mr-1" variant="light-primary">
                                    {{ item.resource }}:{{ item.action }}
                                </b-badge>
                            </template>
                            <template #cell(action)="data">
                                <div class="text-right">
                                    <b-button variant="flat-primary" :disabled="data.item.type == 0 || !isIT">
                                        <feather-icon icon="EditIcon" class="mr-50" />
                                        <span class="align-middle" @click="onEdit(data)">编辑</span></b-button>
                                    <b-button variant="flat-danger" :disabled="data.item.type == 0 || !isIT">
                                        <feather-icon icon="DeleteIcon" class="mr-50" />
                                        <span class="align-middle" @click="onDelete(data)">删除</span></b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                    <b-col cols="12">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm" class="my-0" />
                    </b-col>
                </b-card>
            </b-col>
            <!-- role users --->
            <b-col cols="6">
                <b-card>
                    <b-card-header>
                        <b-card-title>角色用户列表</b-card-title>
                        <b-card-title>
                            <b-button variant="primary" @click="showUserSelection = true" :disabled="!currentRole || !isIT">
                                <feather-icon icon="UserPlusIcon" />
                                <span class="align-middle"> 添加用户 </span></b-button>
                        </b-card-title>
                    </b-card-header>
                    <b-table striped hover responsive :items="roleUsers" :fields="userFields">
                        <template #cell(avatar)="data">
                            <b-avatar :src="data.value" />
                        </template>
                        <template #cell(roleId)="data">
                            <span>{{ roleName(data.item.roleId) }}</span>
                        </template>
                        <template #cell(eid)="data">
                            <span>{{ eidName(data.item.eid) }}</span>
                        </template>
                        <template #cell(action)="data">
                            <b-button variant="flat-danger" @click="onRemoveRoleUser(data.item)" :disabled="data.item.default == 1">
                                <feather-icon icon="UserMinusIcon" class="mr-50" />
                                <span class="align-middle">移除</span></b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <!-- user selection dialog --->
        <b-modal centered title="选择成员" size="lg" v-model="showUserSelection" @ok="doSearch" ok-only ok-title="关闭">
            <b-table striped hover responsive :items="toSelectUsers" :fields="userFields">
                <template #cell(avatar)="data">
                    <b-avatar :src="data.value" />
                </template>
                <template #cell(roleId)="data">
                    <span>{{ roleName(data.item.roleId) }}</span>
                </template>
                <template #cell(eid)="data">
                    <span>{{ eidName(data.item.eid) }}</span>
                </template>
                <template #cell(action)="data">
                    <b-button variant="flat-danger" @click="onAddRoleUser(data.item)" :disabled="data.item.default == 1">
                        <feather-icon icon="PlusIcon" class="mr-50" />
                        <span class="align-middle">添加</span></b-button>
                </template>
            </b-table>
        </b-modal>
    </b-card>
</template>
<script>
import {
    BTable,
    BAvatar,
    BBadge,
    BCard,
    BCardTitle,
    BCardText,
    BCardHeader,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    BModal,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BCard,
        BCardTitle,
        BCardText,
        BCardHeader,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BFormCheckbox,
        BButton,
        BModal,
        VueAutosuggest,
    },

    data() {
        return {
            isIT: true,

            eid: 1,

            ent_name: "",
            ent_list: [],
            debounceMilliseconds: 250,
            timeout: null,
            inputProps: {
                id: "autosuggest__input_ajax",
                placeholder: "请输入企业名称查询",
                class: "form-control",
                name: "ajax",
            },
            sectionConfigs: {
                entOptions: {
                    limit: 6,
                    label: "企业名称",
                    onSelected: (selected) => {
                        this.eid = selected.item.id;
                    },
                },
            },

            roles: null,

            currentRole: null,

            filter: null,
            currentPage: null,
            totalRows: null,
            perPage: 30,
            sortBy: null,
            sortDesc: null,
            sortDirection: null,
            filterOn: null,
            fields: [{
                    key: "id",
                    label: "编号",
                },
                {
                    key: "name",
                    label: "角色名称",
                },
                // {
                //   key: "rights",
                //   label: "功能权限",
                // },
                // {
                //   key: "associated",
                //   label: "管理用户数",
                // },
                {
                    key: "action",
                    label: "",
                },
            ],

            // user list
            roleUsers: [],
            allUsers: [],

            // user fields
            userFields: [{
                    key: "avatar",
                    label: "头像",
                },
                {
                    key: "nickName",
                    label: "昵称",
                },
                {
                    key: "email",
                    label: "邮箱",
                },
                {
                    key: "action",
                    label: "操作",
                    tdClass: "text-right",
                    thClass: "text-right",
                },
            ],

            // show user selection dialog
            showUserSelection: false,
        };
    },

    computed: {
        toSelectUsers() {
            return this.allUsers.filter((user) => user.roleId == 0);
        },
    },

    watch: {
        eid() {
            this.doSearch();
        },
    },

    created() {
        //load all org
        this.doSearch();
    },

    methods: {
        doSearch() {
            // debugger
            this.$http.get("/roles?eid=" + this.eid).then((res) => {
                this.roles = res.data.data.list;
                this.totalRows = res.data.data.total;
            });
        },

        loadEnterprises() {
            const params = { params: { q: this.ent_name } };

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$http.get("/enterprises", params).then((res) => {
                    this.ent_list = [{ name: "entOptions", data: res.data.data.list }];
                });
            }, this.debounceMilliseconds);
        },
        renderSuggestion(suggestion) {
            const ent = suggestion.item;
            return (
                <div class="d-flex">
            <div>
              <b-avatar src={ent.logo} class="mr-50"></b-avatar>
            </div>
            <div>
              <span>{ent.name}</span>
            </div>
          </div>
            );
        },
        getSuggestionValue(suggestion) {
            const ent = suggestion.item;
            this.eid = ent.id;
            return ent.name;
        },

        onRoleClicked(item, index, event) {
            // console.log(item, index, event);
            this.currentRole = item;
            this.loadRoleUsers(item.id);
        },

        loadRoleUsers(roleId) {
            this.$http
                .get("/users", {
                    params: {
                        roleId: roleId,
                    },
                })
                .then((res) => {
                    this.allUsers = res.data.data.list;
                    this.roleUsers = this.allUsers.filter((item) => {
                        return item.roleId == roleId;
                    });
                });
        },

        onNewRole() {
            this.$router.push({
                name: "role-edit",
                params: {
                    id: "new",
                },
            });
        },

        onEdit(data) {
            // console.log(data.item);

            this.$router.push({
                name: "role-edit",
                params: {
                    id: data.item.id,
                },
            });
        },

        onDelete(data) {
            // console.log(data.item);
            // predined role can not be deleted
            if (data.item.type == 0) {
                return;
            }

            this.$swal({
                title: "删除确认",
                text: "是否确认删除该角色!",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "确认",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    // TODO: delete ROLE
                }
            });
        },

        onSaveRole(editRole) {
            // console.log('onSaveRole', editRole);
        },

        onRemoveRoleUser(user) {
            console.log("onRemoveRoleUser", user);
            user.roleId = 0;
            this.$http
                .put("/users/" + user.id, user)
                .then((res) => {
                    this.loadRoleUsers(user.roleId);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        onAddRoleUser(user) {
            console.log("onAddRoleUser", user);
            user.roleId = this.currentRole.id;
            this.$http
                .put("/users/" + user.id, user)
                .then((res) => {
                    this.loadRoleUsers(user.roleId);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>